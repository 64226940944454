import { Market, OHLCV, OrderBook } from 'ccxt';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { ECharts } from 'src/components/ECharts';
import { AppConstants } from 'src/constants';
import { usePromise } from 'src/hook/usePromise';
import { httpGet } from 'src/utils/http';
import styled from 'styled-components';
import cccccc from 'ccxt';
import { Clamp, getTimeStr, sleep, whileTrue } from 'src/utils';

const ccxt = (window as any).ccxt as typeof cccccc;

export const PageDownIndex: React.FC<{}> = (props) => {
  const [cex, _cex] = useState(() => new ccxt.pro.bybit());
  const once = useRef(false);

  useEffect(() => {
    if (once.current) return;
    once.current = true;
    const cexs = Object.values(ccxt.pro).map((cex) => {
      try {
        return new cex();
      } catch (err) {
        return null;
      }
    });
    cexs.forEach((cex) => {
      if (!cex) return;
      cex.loadMarkets().then(async (ress) => {
        const markets = Object.values(ress).filter((c) => c?.active && c.base === AppConstants.TokenSymbol);
        if (markets.length === 0) return;
        console.log(cex.name);
      });
    });
  }, []);

  return <PageIndexStyle></PageIndexStyle>;
};

const PageIndexStyle = styled.div`
  position: relative;
  z-index: 1;
`;
