import { FundingRateHistory, Market, OHLCV, OrderBook } from 'ccxt';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { ECharts } from 'src/components/ECharts';
import { AppConstants } from 'src/constants';
import { usePromise } from 'src/hook/usePromise';
import { httpGet } from 'src/utils/http';
import styled from 'styled-components';
import cccccc from 'ccxt';
import { Clamp, getTimeStr, sleep, whileTrue } from 'src/utils';
import classNames from 'classnames';

const ccxt = (window as any).ccxt as typeof cccccc;

const speekSome = (arg: SpeechSynthesisUtterance) => {
  let utterThis = new SpeechSynthesisUtterance();

  // utterThis.text = text;
  utterThis.lang = 'en-US'; // 汉语
  utterThis.rate = 0.7; //语速
  speechSynthesis.speak(utterThis);
};

let lastText = '';
let speekConfig = {
  rate: 1,
  volume: 0.5,
  open: false,
  lang: 'zh-CN',
  voice: null as null | SpeechSynthesisVoice,
  diffTime: 5,
};

let lastSpeekText = '';
let nextSpeekTime = 0;
let utterThis = new SpeechSynthesisUtterance();
let timeDiff = 0;
whileTrue(async () => {
  if (!speekConfig.open) return await sleep(2000);
  if (!lastText) return await sleep(2000);
  const now = Date.now();
  if (lastSpeekText === lastText && nextSpeekTime > now) return await sleep(200);
  nextSpeekTime = now + speekConfig.diffTime * 1000;
  utterThis.text = lastText;
  utterThis.lang = speekConfig.lang;
  utterThis.rate = speekConfig.rate;
  utterThis.volume = speekConfig.volume;
  if (speekConfig.voice) utterThis.voice = speekConfig.voice;
  speechSynthesis.cancel();
  lastSpeekText = lastText;
  speechSynthesis.speak(utterThis);
  await sleep(500);
});

export const PageIndex: React.FC<{}> = (props) => {
  const [data, _data] = useState<any>();
  const [cex, _cex] = useState(() => new ccxt.pro.bybit());
  const [spot, _spot] = useState<Market>();
  const [linear, _linear] = useState<Market>();
  const [watchSpot, _watchSpot] = useState<Market[]>();
  const [watchPrices, _watchPrices] = useState<Record<string, { name: string; value: string }>>({});
  const once = useRef(false);
  const [indexPrices, _indexPrices] = useState<Record<number, { time: number; PremiumIndex: number; avePremiumIndex: number; sumWeight: number; weightAll: number; F: number }>>({});
  const [tableKeys, _tableKeys] = useState<Array<{ key: number; name: string; weight: number }>>([]);
  const [indexPriceMap, _indexPriceMap] = useState<Record<number, OHLCV>>({});
  const [linearAvePrice, _linearAvePrice] = useState({ buy: 0, sell: 0 });
  const [spotBS, _spotBS] = useState({ buy: 0, sell: 0 });
  const [spotOHLCV, _spotOHLCV] = useState<Record<number, OHLCV>>({});
  const [time, _time] = useState(60);
  const [speek, _speek] = useState(speekConfig);
  const [voices, _voices] = useState<SpeechSynthesisVoice[]>([]);
  speekConfig = speek;

  useEffect(() => {
    whileTrue(async () => {
      const res = speechSynthesis.getVoices();
      if (res.length) {
        _voices(res.filter((item) => item.lang.includes('zh')));
        return 'stop';
      }
      await sleep(500);
    });
  }, []);

  useEffect(() => {
    if (!linearAvePrice.buy) return;
    if (!spotBS.sell) return;
    const diff = (10000 * (linearAvePrice.buy - spotBS.sell)).toFixed(0);
    lastText = diff;
  }, [linearAvePrice.buy, spotBS.sell]);

  useEffect(() => {
    let lastNow = 0;
    const timer = setInterval(() => {
      let now = Date.now() + timeDiff;
      const sec = now % 60000;
      _time(60 - Math.floor(sec / 1000));
      now = now - (now % 60000);
      if (lastNow === now) return;
      lastNow = now;
      const cur8h = now - (now % (8 * 60 * 60 * 1000));
      const list = Array.from({ length: 60 }, (_, i) => {
        const key = now - i * 60000;
        return { key, name: getTimeStr(key).replace(/:00$/, ''), weight: 1 + (key - cur8h) / 60000 };
      });
      _tableKeys(list);
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    if (once.current) return;
    once.current = true;
    cex.loadMarkets().then(async (ress) => {
      const markets = Object.values(ress).filter((c) => c?.active);
      const spot = markets.find((c) => c?.spot && c.base === AppConstants.TokenSymbol);
      _spot(spot);
      const linear = markets.find((c) => c?.linear && c?.base === AppConstants.TokenSymbol);
      _linear(linear);
      const watchList = markets.filter((c) => c?.spot && AppConstants.WatchList.includes(c.symbol!));
      _watchSpot(watchList);
      let spotOrderBook: OrderBook | null = null;
      let linearOrderBook: OrderBook | null = null;
      let PremiumIndexOHLCV: OHLCV[] = [];
      let indexOHLCV: OHLCV[] = [];
      // message.push(`${getTimeStr(item[0]!).replace(/:00$/, '')}: ${item[4]?.toFixed(8)} ${P.toFixed(8)} ${(F * 100).toFixed(6)}`);
      let avePriceBuy = 0; // 合约加权买价
      let avePriceSell = 0; // 合约加权卖价
      // BTC,ETH 价格
      watchList.forEach((token) => {
        whileTrue(async () => {
          if (!token) return 'stop';
          const timer = sleep(500);
          const res = await cex.watchOrderBook(token.symbol).catch((err) => Promise.resolve(String(err)));
          if (typeof res === 'string') {
            console.error(res);
            if (res.match(/NotSupported/)) {
              alert(`${cex.name} NotSupported ${token.symbol} watchOrderBook`);
              return 'stop';
            }
            return;
          }
          _watchPrices((v) => ({ ...v, [token.symbol]: { name: token.symbol, value: `${res.asks[0][0]} (${new Date(res.timestamp!).toLocaleTimeString()})` } }));
          await timer;
        });
      });

      // spot.watchOrderBook
      whileTrue(async () => {
        if (!spot) return 'stop';
        const timer = sleep(200);
        const res = await cex.watchOrderBook(spot.symbol).catch((err) => Promise.resolve(String(err)));
        if (typeof res === 'string') {
          console.error(res);
          if (res.match(/NotSupported/)) {
            alert(`${cex.name} NotSupported ${spot.symbol} watchOrderBook`);
            return 'stop';
          }
          return;
        }
        spotOrderBook = res;
        _spotBS({ buy: res.bids[0][0]!, sell: res.asks[0][0]! });
        await timer;
      });
      // spot.fetchOHLCV
      whileTrue(async () => {
        if (!spot) return 'stop';
        const minTimer = sleep(5000);
        const res = await cex.fetchOHLCV(spot.symbol, '1m').catch((err) => Promise.resolve(String(err)));
        if (typeof res === 'string') {
          console.error(res);
          if (res.match(/NotSupported/)) {
            alert(`${cex.name} NotSupported ${spot.symbol} fetchOHLCV`);
            return 'stop';
          }
          return;
        }
        let map: typeof spotOHLCV = {};
        res.forEach((item) => {
          map[item[0]!] = item;
        });
        _spotOHLCV(map);
        await minTimer;
      });

      // linear.watchOrderBook
      whileTrue(async () => {
        if (!linear) return 'stop';
        const res = await cex.watchOrderBook(linear.symbol).catch((err) => Promise.resolve(String(err)));
        if (typeof res === 'string') {
          console.error(res);
          if (res.match(/NotSupported/)) {
            alert(`${cex.name} NotSupported ${linear.symbol} watchOrderBook`);
            return 'stop';
          }
          return;
        }
        linearOrderBook = res;
        const weight = 3750;
        const avoPrice = (() => {
          const buyList: Array<{ price: number; vol: number }> = [];
          let needBuy = weight;
          for (let i = 0; i <= 100; i++) {
            if (needBuy <= 0) break;
            const bid = res.bids[i];
            if (!bid) break;
            const vol = bid[0]! * bid[1]!;
            if (vol >= needBuy) {
              buyList.push({ price: bid[0]!, vol: needBuy });
              needBuy = 0;
            } else {
              buyList.push({ price: bid[0]!, vol });
              needBuy -= vol;
            }
          }
          const sellList: Array<{ price: number; vol: number }> = [];
          let needSell = weight;
          for (let i = 0; i <= 100; i++) {
            if (needSell <= 0) break;
            const bid = res.asks[i];
            if (!bid) break;
            const vol = bid[0]! * bid[1]!;
            if (vol >= needSell) {
              sellList.push({ price: bid[0]!, vol: needSell });
              needSell = 0;
            } else {
              sellList.push({ price: bid[0]!, vol });
              needSell -= vol;
            }
          }
          const b1 = buyList.reduce((sum, item) => sum + item.price * item.vol, 0) / weight;
          const s1 = sellList.reduce((sum, item) => sum + item.price * item.vol, 0) / weight;
          avePriceBuy = parseFloat(b1.toFixed(4));
          avePriceSell = parseFloat(s1.toFixed(4));
          _linearAvePrice({ buy: avePriceBuy, sell: avePriceSell });
        })();
      });
      // linear.fetchPremiumIndexOHLCV
      whileTrue(async () => {
        if (!linear) return 'stop';
        const minTimer = sleep(5000);
        const premium = await cex.fetchPremiumIndexOHLCV(linear.symbol, '1m', undefined, 480).catch((err) => Promise.resolve(String(err)));
        if (typeof premium === 'string') {
          console.error('fetchPremiumIndexOHLCV', premium);
          if (premium.match(/NotSupported/)) {
            alert(`${cex.name} NotSupported ${linear.symbol} fetchPremiumIndexOHLCV`);
            return 'stop';
          }
          return;
        }
        PremiumIndexOHLCV = premium;
        let beginI = -1;
        const now = Date.now();
        const cur8h = now - (now % (8 * 60 * 60 * 1000));
        let sumWeight = 0;
        let weightAll = 0;
        const PremiumIndexMap: typeof indexPrices = {};
        premium.forEach((item, i) => {
          const time = item[0]!;
          if (time <= cur8h) return;
          if (beginI === -1) beginI = i;
          const weight = i - beginI + 1;
          sumWeight = parseFloat((sumWeight + weight * item[4]!).toFixed(8));
          weightAll = weight + weightAll;
          let P = sumWeight / weightAll;
          const F = (P + Clamp(0.0001 - P, -0.0005, 0.0005)) * 100;
          PremiumIndexMap[item[0]!] = { time: item[0]!, PremiumIndex: item[4]! * 100, sumWeight, weightAll, avePremiumIndex: parseFloat(P.toFixed(8)) * 100, F };
        });
        _indexPrices(PremiumIndexMap);
        await minTimer;
      });
      // linear.fetchIndexOHLCV
      whileTrue(async () => {
        if (!linear) return 'stop';
        const minTimer = sleep(5000);
        const index = await cex.fetchIndexOHLCV(linear.symbol, '1m', undefined, 480).catch((err) => Promise.resolve(String(err)));
        if (typeof index === 'string') {
          console.error('fetchIndexOHLCV', index);
          if (index.match(/NotSupported/)) {
            alert(`${cex.name} NotSupported ${linear.symbol} fetchIndexOHLCV`);
            return 'stop';
          }
          return;
        }
        indexOHLCV = index;
        const result: Record<number, OHLCV> = {};
        index.forEach((item) => {
          result[item[0]!] = item;
        });
        _indexPriceMap(result);
        await minTimer;
      });

      whileTrue(async () => {
        const timer = sleep(5 * 60000);
        const now = Date.now();
        const res = await cex.fetchTime();
        if (!res) return;
        timeDiff = now - res!;
        await timer;
      });
    });
  }, []);

  return (
    <PageIndexStyle>
      <div className="watchListPrices">
        {Object.values(watchPrices).map((item) => (
          <div key={item.name}>
            {item.name}: {item.value}
          </div>
        ))}
      </div>
      <div className="linearBuyPrice">
        <div>
          加权卖价: <span className={classNames({ aveActive: linearAvePrice.sell <= spotBS.sell })}>{linearAvePrice.sell}</span> ({(10000 * (linearAvePrice.sell - spotBS.sell)).toFixed(0)})
        </div>
        <div className="line">
          <div>
            加权买价: <span className={classNames({ aveActive: linearAvePrice.sell > spotBS.sell })}>{linearAvePrice.buy}</span> ({(10000 * (linearAvePrice.buy - spotBS.sell)).toFixed(0)})
          </div>
          <div className="time">{time}</div>
        </div>
      </div>
      <div className="indexPrices">
        <table border={1} cellSpacing={0}>
          <thead>
            <tr>
              <th> 时间 </th>
              <th>现货卖一</th>
              <th>指数价格</th>
              <th>溢价指数%</th>
              <th>平均溢价%</th>
              <th>费率%</th>
            </tr>
          </thead>
          <tbody>
            {tableKeys.map((tr, index) => {
              if (index === 0) {
                const prevKey = tr.key - 60000;
                const ppKey = tr.key - 120000;
                const prev = indexPrices[prevKey] || indexPrices[ppKey];
                let indexPrice = indexPriceMap[tr.key]?.[4];
                const spotClose = spotOHLCV[tr.key]?.[4] || spotOHLCV[prevKey]?.[4] || spotOHLCV[ppKey]?.[4];
                indexPrice = indexPrice && spotClose ? indexPrice - 0.8861 * spotClose + 0.8861 * spotBS.sell : indexPrice;
                indexPrice = parseFloat(indexPrice?.toFixed(4) || spotClose?.toFixed(4) || '0');
                if (!indexPrice) {
                  console.log('indexPrice', spotClose);
                  return null;
                }

                if (!prev) {
                  console.log('prev');
                  return null;
                }
                if (!linearAvePrice.buy) {
                  console.log('linearAvePrice');
                  return null;
                }
                const p1 = Math.max(0, linearAvePrice.buy - indexPrice);
                const p2 = Math.max(0, indexPrice - linearAvePrice.sell);
                const P = (p1 - p2) / indexPrice;
                const avePremiumIndex = (prev.sumWeight + tr.weight * P) / (prev.weightAll + tr.weight);
                const F = avePremiumIndex + Clamp(0.0001 - avePremiumIndex, -0.0005, 0.0005);
                return (
                  <tr key={tr.key} className="new">
                    <td title={tr.weight.toString()}>{tr.name}</td>
                    <td className="s1">{spotBS.sell.toFixed(4)}</td>
                    <td>{indexPriceMap[tr.key]?.[4] ? indexPrice : '-'}</td>
                    <td>{(P * 100).toFixed(5) || '-'}</td>
                    <td>{(avePremiumIndex * 100).toFixed(5) || '-'}</td>
                    <td>{(F * 100).toFixed(5) || '-'}</td>
                  </tr>
                );
              }
              return (
                <tr key={tr.key}>
                  <td title={tr.weight.toString()}>{tr.name}</td>
                  <td>{spotOHLCV[tr.key]?.[4]?.toFixed(4) || '-'}</td>
                  <td>{indexPriceMap[tr.key]?.[4]?.toFixed(4) || '-'}</td>
                  <td>{indexPrices[tr.key]?.PremiumIndex.toFixed(5) || '-'}</td>
                  <td>{indexPrices[tr.key]?.avePremiumIndex.toFixed(5) || '-'}</td>
                  <td>{indexPrices[tr.key]?.F.toFixed(5) || '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="setting">
        <div>
          语音:{' '}
          <button
            onClick={() => {
              _speek((v) => ({ ...v, open: !v.open }));
            }}
          >
            {speek.open ? '关闭' : '启动语音播报'}
          </button>
        </div>
        <div>
          没变化时, 隔
          <button
            onClick={() => {
              _speek((v) => ({ ...v, diffTime: Math.max(1, parseFloat((v.diffTime - 1).toFixed(2))) }));
            }}
          >
            {' '}
            -{' '}
          </button>
          {speek.diffTime}秒
          <button
            onClick={() => {
              _speek((v) => ({ ...v, diffTime: Math.min(60, parseFloat((v.diffTime + 1).toFixed(2))) }));
            }}
          >
            {' '}
            +{' '}
          </button>
          报价
        </div>
        <div>
          音量:{' '}
          <button
            onClick={() => {
              _speek((v) => ({ ...v, volume: Math.max(0.1, parseFloat((v.volume - 0.2).toFixed(2))) }));
            }}
          >
            {' '}
            -{' '}
          </button>
          {speek.volume.toFixed(1)}
          <button
            onClick={() => {
              _speek((v) => ({ ...v, volume: Math.min(2, parseFloat((v.volume + 0.2).toFixed(2))) }));
            }}
          >
            {' '}
            +{' '}
          </button>
        </div>
        <div>
          速度:{' '}
          <button
            onClick={() => {
              _speek((v) => ({ ...v, rate: Math.max(0.1, parseFloat((v.rate - 0.2).toFixed(2))) }));
            }}
          >
            {' '}
            -{' '}
          </button>
          {speek.rate.toFixed(1)}
          <button
            onClick={() => {
              _speek((v) => ({ ...v, rate: Math.min(10, parseFloat((v.rate + 0.2).toFixed(2))) }));
            }}
          >
            {' '}
            +{' '}
          </button>
        </div>
        <div>
          语音包:{' '}
          <select
            onChange={(e) => {
              _speek((v) => ({ ...v, voice: voices[Number(e.target.value)] }));
            }}
          >
            {voices.map((voice, index) => (
              <option value={index} key={voice.name}>
                {voice.name}({voice.lang})
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <h3>说明</h3>
        <a href="https://www.bybit.com/zh-MY/announcement-info/fund-rate/" target="_blank" rel="noreferrer">
          资金费率(F)
        </a>
        <br />
        <br />
        <a href="https://www.bybit.com/zh-MY/announcement-info/index-price/" target="_blank" rel="noreferrer">
          指数价格 & 溢价指数(P)
        </a>
        <br />
        <br />
        <a href="https://www.bybit.com/en/help-center/article/What-is-funding-rate-and-predicted-rate" target="_blank" rel="noreferrer">
          冲击买方价格/加权买价(Impact Bid Price) & 冲击卖方价格/加权卖价(Impact Ask Price)
        </a>
        <br />
        <br />
        P = [Max(0, 加权买价 - 指数价格) - Max (0, 指数价格 - 加权卖价)]/指数价格
        <br />
        <br />
        平均溢价指数（P）=（溢价指数_1 * 1 + 溢价指数_2 * 2 + ... + 溢价指数_480 * 480）/（1 + 2 + ... + 480）
        <br />
        <br />
        <span title="Clamp函数可以将 0.01% - P 的数值限制在一个给定的区间[min, max]内">F = P + Clamp(0.01% - P, 0.05%, -0.05%)</span>
        <br />
        <br />
      </div>
    </PageIndexStyle>
  );
};

const PageIndexStyle = styled.div`
  position: relative;
  z-index: 1;
  > .watchListPrices {
    font-size: 12px;
    color: #666;
  }
  .aveActive {
    color: red;
    font-size: 1.5rem;
  }
  > .indexPrices {
    overflow: auto;
    max-height: 200px;
    width: 400px;
    max-width: 100vw;
    > table {
      text-align: center;
      border-color: #e0e0e0;
      border-collapse: collapse;
      overflow: auto;
      width: 100%;
      color: #666;
      .new {
        color: #0300b3;
        position: sticky;
        top: 20px;
        z-index: 1;
        background: #fff; // any bg-color to overlap
        /* font-size: 1.1rem; */
        font-size: 1.1rem;
        > .s1 {
          font-size: 1.2rem;
        }
      }
    }
    > table th {
      font-size: 12px;
      position: sticky;
      top: 0;
      z-index: 1;
      background: #fff; // any bg-color to overlap
    }
  }
  > .linearBuyPrice {
    > div {
      &.line {
        display: flex;
        justify-content: space-between;
        width: 400px;
        max-width: 100vw;
        > .time {
          font-size: 1.2rem;
          color: #0300b3;
          padding-right: 12px;
        }
      }
    }
  }
`;
