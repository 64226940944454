export const sleep = (t = 200) => new Promise((r) => setTimeout(r, t));

export const showError = (message: string) => {
  try {
    window?.Telegram?.WebApp?.showPopup({ title: 'Error', message });
  } catch (e) {
    window.alert(message);
    console.error(e);
  }
  return null;
};

export const stringifyJson = (json: any) => {
  return JSON.stringify(json, (key, value) => {
    if (typeof value === 'bigint') return String(value);
    return value;
  });
};

export const getTimeStr = (time: number, day?: boolean) => {
  const date = new Date(time);
  const revert = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
  if (!day) return revert;
  const dayStr = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  return `${dayStr} ${revert}`;
};

export const whileTrue = async (callback: () => Promise<any>) => {
  while (true) {
    try {
      const res = await callback();
      if (res === 'stop') return;
    } catch (err) {
      console.error(err);
    }
  }
};

export const Clamp = (value: number, min: number, max: number) => {
  if (value < min) value = min;
  if (value > max) value = max;
  return value;
};

export const loadImage = async (url: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject(err);
    };
  });
};

export const textMiddleEllipsis = (text = '', start: number = 6, end: number = 3) => {
  if (text.length <= start + end) return text;
  return `${text.substring(0, start)}...${text.substring(text.length - end)}`;
};
