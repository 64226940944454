import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import { RecoilRoot } from 'recoil';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PageIndex } from 'src/pages/index';
import { PageDownIndex } from './pages/down';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PageIndex />} />
          <Route path="/down" element={<PageDownIndex />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
);
